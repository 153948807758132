<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerLogo="logotypes/facebook_36.svg"
            headerText="Setup Your Facebook Collect"
            @continue="continueForm"
            @reset="resetForm"
            @input="onFormInput"
            ref="tabbedForm"
            @finish="createPipeline"
            :update="updatePipeline"
            :details="isDetails"
            :dirty="dirty"
            :fetched="fetched"
            :update-redirect="updateRedirect"
        >
            <template v-slot:step-2-feedback>
                <b-row>
                    <b-col>
                        <Feedback v-if="fbError" :state="fbError.state" :invalid="fbError.message"></Feedback>
                    </b-col>
                </b-row>
            </template>
        </wit-tabbed-form>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, minLength} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import Feedback from '@/components/Feedback'
import Loading from '@/components/loading.vue'
import SelectFacebookToken from '@/components/Tokens/Selects/SelectFacebookToken.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {collectMixin} from '@/mixins/collectMixin'
import {formMixin} from '@/mixins/formMixin'

export default {
    components: {
        Loading,
        Feedback,
        SelectFacebookToken,
        Treeselect,
        WitTabbedForm,
    },
    data() {
        return {
            pipelineSettings: {
                name: null,
                actionAttributionWindows: ['1d_click', '1d_view', '7d_click', '7d_view', 'default'],
            },
            workflowForm: {
                historicalDateRange: 30,
            },
            account: null,
            fbError: null,
            tokenId: null,
            actionAttributionWindowsOptions: [
                {id: '1d_click', label: '1 Day Click'},
                {id: '1d_view', label: '1 Day View'},
                {id: '7d_click', label: '7 Day Click'},
                {id: '7d_view', label: '7 Day View'},
                {id: 'default', label: 'Default'},
            ],
        }
    },
    mixins: [collectMixin, formMixin, validationMixin],
    validations: {
        pipelineSettings: {
            name: {required},
            actionAttributionWindows: {
                required,
                minLength: minLength(1),
            },
        },
        tokenId: {
            required,
        },
        account: {
            required,
        },
        workflowForm: {
            historicalDateRange: {},
        },
    },
    async created() {
        if (!this.isDetails) {
            const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
            const formSessionId = formSessionMap[this.$route.path].id

            this.$gtm.trackEvent({
                event: 'create_resource_start',
                action: 'create',
                value: formSessionId,
            })

            await this.$store.dispatch('facebook/init')
        } else {
            this.fetchCollect()
        }
    },
    computed: {
        ...mapGetters({
            accounts: 'facebook/accounts',
            authServices: 'google/authServices',
            activeProject: 'project/active',
            loading: 'loading/state',
        }),
        isDetails() {
            return Boolean(this.$route.params.id)
        },
        cannotContinue() {
            return this.isDetails
        },
        formId() {
            return this.isDetails ? 'facebook-create-form' : 'facebook-details-form'
        },
        steps() {
            return [
                {
                    name: 'Collect Settings',
                    invalid: this.$v.pipelineSettings.$invalid,
                    inputs: [
                        {
                            name: 'name',
                            value: this.$v.pipelineSettings.name.$model,
                            model: this.$v.pipelineSettings.name,
                            type: 'text',
                            inputLabel: 'Collect name',
                            placeholder: 'Enter your collect name',
                            invalid: 'This field is required',
                            valid: 'Name is valid',
                        },
                        {
                            name: 'attribution-windows',
                            value: this.$v.pipelineSettings.actionAttributionWindows.$model,
                            model: this.$v.pipelineSettings.actionAttributionWindows,
                            type: 'treeselect',
                            inputLabel: 'Attribution Windows',
                            multiple: true,
                            options: this.actionAttributionWindowsOptions,
                            invalid: 'At least 1 value is required',
                            valid: 'Value is valid',
                            isAdvanced: true,
                        },
                    ],
                },
                {
                    name: 'Authorize Facebook',
                    invalid: this.$v.pipelineSettings.$invalid || this.$v.tokenId.$invalid || this.$v.account.$invalid,
                    inputs: [
                        {
                            name: 'token',
                            value: this.$v.tokenId.$model,
                            model: this.$v.tokenId,
                            type: 'facebook-token',
                            scope: 'ads_management',
                            onInput: this.getFacebookAccounts,
                            inputLabel: 'Authorize with your Facebook account',
                        },
                        {
                            vIf: this.accounts.length,
                            name: 'facebook-account',
                            value: this.$v.account.$model,
                            model: this.$v.account,
                            type: 'select',
                            inputLabel: 'Facebook Accounts',
                            invalid: 'This field is required',
                            valid: 'Account is valid',
                            placeholder: 'Select accounts',
                            options: this.accounts,
                            label: 'name',
                            trackBy: 'id',
                        },
                    ],
                },
                this.isDetails
                    ? null
                    : {
                          name: 'Data Collection Settings',
                          invalid: this.$v.$invalid,
                          inputs: [
                              {
                                  name: 'range',
                                  value: this.$v.workflowForm.historicalDateRange.$model,
                                  model: this.$v.workflowForm.historicalDateRange,
                                  type: 'range',
                                  inputLabel: 'Collect data for last (days)',
                                  min: 0,
                                  max: 365,
                              },
                          ],
                      },
            ].filter(Boolean)
        },
    },
    methods: {
        async getFacebookAccounts() {
            try {
                this.fbError = null
                this.$store.commit('loading/PROCESSING', `Fetching Facebook Accounts`)
                await this.$store.dispatch('facebook/setFacebookAccounts', this.tokenId)
                this.$store.commit('loading/PROCESSED')
            } catch (exception) {
                this.$errorHandler.report(exception, 'Could not fetch facebook accounts')
                this.$store.commit('loading/PROCESSED')
                this.fbError = {
                    state: false,
                    message: exception.response.data.data,
                }
            }
        },

        async createPipeline(step) {
            this.fbError = null

            step.check()
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                const data = {
                    name: this.pipelineSettings.name,
                    settings: {
                        accountId: this.account.id,
                        accountName: this.account.name,
                        actionAttributionWindows: this.pipelineSettings.actionAttributionWindows,
                    },
                    tokenId: this.tokenId,
                    projectId: this.activeProject.id,
                    ...this.workflowForm,
                }

                this.$store.commit('loading/PROCESSING', `Creating collect...`)
                try {
                    await this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/collect/facebook/create`, data)

                    this.resetForm()

                    const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                    const formSessionId = formSessionMap[this.$route.path].id

                    this.$gtm.trackEvent({
                        event: 'create_resource_finish',
                        action: 'finish',
                        value: formSessionId,
                    })

                    formSessionMap[this.$route.path] = null
                    localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionMap))

                    this.$store.commit('loading/PROCESSED')
                    this.$projectRouter.push(this.redirectUrl)
                } catch (exception) {
                    this.fbError = {
                        state: false,
                        message: exception.response.data.data,
                    }
                    this.$errorHandler.report(exception, 'Could not create form')
                }
            }
        },

        async fetchCollect() {
            try {
                this.$store.commit('loading/PROCESSING', `Fetching Collect Details`)

                const {id} = this.$route.params

                const facebookPipeline = await this.axios.get(
                    `${process.env.VUE_APP_NODE_API_HOST}/collect/facebook/${id}`
                )

                this.pipeline = facebookPipeline.data.data
                this.pipelineSettings.name = facebookPipeline.data.data.name
                this.pipelineSettings.actionAttributionWindows =
                    facebookPipeline.data.data.settings.actionAttributionWindows
                this.tokenId = this.pipeline.tokenRef.id
                await this.$store.dispatch('facebook/setFacebookAccounts', this.tokenId)
                this.account = this.accounts.find(el => el.id === this.pipeline.settings.accountId)
                this.$v.$reset()
                this.fetched = true

                this.$store.commit('loading/PROCESSED')
            } catch (exception) {
                this.$errorHandler.report(exception, 'Could not prepare details')
                this.$store.commit('loading/PROCESSED')
                this.$projectRouter.push('/collect?formError=true')
            }
        },

        async updatePipeline() {
            this.fbError = null

            if (!this.$v.$invalid) {
                this.$store.commit('loading/PROCESSING', `Updating collect settings...`)

                try {
                    const data = {
                        name: this.pipelineSettings.name,
                        tokenId: this.tokenId,
                        settings: {
                            accountId: this.account.id,
                            accountName: this.account.name,
                            actionAttributionWindows: this.pipelineSettings.actionAttributionWindows,
                        },
                    }

                    const pipeline = await this.axios.post(
                        `${process.env.VUE_APP_NODE_API_HOST}/collect/facebook/${this.pipeline.id}`,
                        data
                    )
                    this.$v.$reset()

                    this.$store.commit('loading/PROCESSED')
                } catch (exception) {
                    this.fbError = {
                        state: false,
                        message: exception.response.data.data,
                    }
                    this.$errorHandler.report(exception, 'Could not update form')
                }
            }
        },
    },
}
</script>

<style scoped>
#pipeline-timezone {
    z-index: 1001;
}
#join-with {
    z-index: 0;
}
.switch-slider {
    z-index: 0;
}
#join-with-pipeline {
    z-index: 100;
}
</style>
